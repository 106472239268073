<template>
  <!--
    <p v-if="isValid" class="text-success">Valid</p>
    <p v-else class="text-danger">Invalid</p>
    -->
  <AutomaticForm
    title="General information"
    :form-fields="props.formFields"
    prefix="scenario"
    :db-object="dbObject"
    @valid="onValid"
    :isDistrict="true"
  ></AutomaticForm>

  <div class="alert alert-warning p-1 mb-2" role="alert">
    <h3><b>Notice:</b></h3>
    <ul class="m-0 p-1 list-unstyled">
      <li>
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        The investment form is filled with default values, please make any
        necessary changes considering your scenario.
      </li>
    </ul>
  </div>

  <!-- :prefix="generateUniquePrefixForObject(dbObject)" 
    prefix="scenario[investments_attributes]"
   -->
  <AutomaticForm
    title="Financial information"
    :form-fields="props.investmentFormFields"
    :db-object="finalInvestmentDbObject()"
    :prefix="generateUniquePrefixForObject(dbObject)"
    @valid="onValid"
    :isDistrict="true"
  ></AutomaticForm>
</template>

<script setup>
import AutomaticForm from "../shared/AutomaticForm.vue";
import NestedAutomaticForm from "../shared/NestedAutomaticForm.vue";

import { computed, ref } from "vue";

const props = defineProps({
  formFields: {
    type: Array,
    required: true,
  },
  dbObject: {
    type: Object,
    required: true,
  },
  investmentFormFields: {
    type: Array,
    required: true,
  },
  investmentDbObjects: {
    type: Array,
    required: false,
    default: [],
  },
});

const isValid = ref(false);

function onValid(e) {
  isValid.value = isValid.value && e;
}

function generateUniquePrefixForObject(dbObject) {
  const uniqueId = generateUniqueKeyForObject(dbObject); // generateUniqueKeyForObject(dbObject)
  return `scenario[investments_attributes][${uniqueId}]`;
}
function generateUniqueKeyForObject(dbObject) {
  // Use the database id if it exists. Otherwise add the current timestamp (I consider this a same method since the
  // user most probably will not click the "Add another component" button at the exact same time.
  let uniqueId = Date.now() + Math.floor(Math.random() * 500) + 1;
  if (dbObject.id) {
    uniqueId = dbObject.id;
  }
  return uniqueId;
}

function finalInvestmentDbObject() {
  if(props.investmentDbObjects.length === 0) {
    return []
  }
  return props.investmentDbObjects[0]
}
</script>

<style scoped></style>
