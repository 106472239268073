<template>
  <AutomaticForm title="LCI component entry form" :form-fields="props.formFields" prefix="lci_component"
                 :db-object="dbObject"></AutomaticForm>
</template>

<script setup>
import AutomaticForm from "../shared/AutomaticForm.vue";
const props = defineProps({
  dbObject: {
    type: Object,
    required: true
  },
  formFields: {
    type: Array,
    required: true
  }
})

</script>

<style scoped>

</style>