<!--
This Vue component is a modal selector where the user can select a Building from the Verify-B
and a scenario that belongs to the selected building in order to transfer the building details along
with the scenario components to a district.
This component includes a button that toggles the modal selector. The modal itself includes 2 drop down menus:
(1) the buildings list of the current user, and (2 - enabled only after the user selects a building) the scenarios
 list of the selected building.

This component exists in the form of new building in districts

app/javascript/vue/shared/AutomaticFormBuilding.vue

In order to use the component you have to
1. import the component
  eg. import BuildingScenarioSelector from "./BuildingScenarioSelector.vue";

2. use it in the vue file
        <SelectorComponent
        :user_buildings="user_buildings"
      />
-->
<template>
  <div>
    <!-- Button to trigger modal -->
    <button
      @click="showModal = true"
      class="btn btn-primary"
      type="button"
      v-show="showButton"
    >
      Clone Building From Verify-B
    </button>

    <!-- Modal -->
    <div v-show="showModal">
      <div class="popup-window" style="margin-left: 10px; margin-right: 10px">
        <form
          :action="formActionUrl"
          @submit="saveSelections"
          id="modal-form"
          method="post"
        >
          <button
            type="button"
            @click="showModal = false"
            class="btn btn-danger"
          >
            <i class="fa fa-close" aria-hidden="true"></i>
          </button>

          <h1 style="text-align: center; margin-top: 25px">
            Clone Building from your Verify-B account
          </h1>
          <h3 style="text-align: center">
            <i>
              Select a building from your Verify-B account and then the
              preferred scenario to be imported into the new district building
            </i>
          </h3>
          <div
            class="window-container"
            style="height: fit-content; width: auto"
          >
            <div class="row">
              <div class="col-6" style="text-align: center">
                <h5>Verify-B Building</h5>
                <select v-model="buildingSelection" @change="loadScenarios()">
                  <option
                    v-for="option in buildingsOptions"
                    :value="option.value"
                    :key="option.value"
                  >
                    {{ option.value }} - {{ option.text }}
                  </option>
                </select>
              </div>
              <div class="col-6" style="text-align: center">
                <h5>Building Scenario</h5>
                <select
                  v-model="scenarioSelection"
                  :disabled="!isSecondSelectorEnabled"
                >
                  <option
                    v-for="option in scenariosOptions"
                    :value="option.value"
                    :key="option.value"
                  >
                    {{ option.value }} - {{ option.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="window-controls">
            <button
              class="btn btn-success"
              type="button"
              @click="saveSelections"
            >
              Clone
            </button>
            <button
              class="btn btn-danger ml-3"
              type="button"
              @click="showModal = false"
            >
              Close
            </button>
          </div>
          <input
            type="hidden"
            name="authenticity_token"
            :value="authenticityToken"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, onMounted, ref, watch } from "vue";
const props = defineProps({
  user_buildings: {
    type: Array,
    required: true,
  },
});

let formActionUrl = ref("");
let showButton = ref(true);
let showModal = ref(false);
let buildingSelection = ref(null);
let scenarioSelection = ref(null);
let isSecondSelectorEnabled = ref(false);
let buildingsOptions = ref([]);
let scenariosOptions = ref([]);
let authenticityToken;

onBeforeMount(() => {
  authenticityToken = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");
});
onMounted(() => {
  if (document.getElementsByClassName("edit_building").length > 0) {
    showButton = false;
  }
  initializeBuildingsOptions();
});
function initializeBuildingsOptions() {
  // Map user_buildings data to buildingsOptions array
  buildingsOptions = props.user_buildings.map((building) => ({
    value: building.id.toString(), // Convert id to string if necessary
    text: building.name,
    scenarios: building.scenarios, // Store scenarios for each building
  }));
}
function loadScenarios() {
  // Find the selected building
  const selectedBuilding = buildingsOptions.find(
    (building) => building.value === buildingSelection.value
  );
  if (selectedBuilding) {
    // Update scenariosOptions with scenarios of the selected building
    scenariosOptions = [
      { value: null, text: "Please select a Scenario" },
      ...selectedBuilding.scenarios.map((scenario) => ({
        value: scenario.id.toString(),
        text: scenario.name,
      })),
    ];
    console.log(scenariosOptions);
  } else {
    // Clear scenariosOptions if no building is selected
    scenariosOptions = [{ value: null, text: "Please select a Scenario" }];
  }

  // Enable/disable second selector based on whether scenarios are available
  isSecondSelectorEnabled = scenariosOptions.length > 1; // Checking length > 1 to account for placeholder option
}
function exportResourceId(resource) {
  const url = window.location.href;
  // Locates scenario or district id from the URL.
  // resource: Receives -> { "scenarios", "districts" }
  const startIdx = url.indexOf(`${resource}/`) + `${resource}/`.length;
  // Extract the substring starting from "scenarios/"
  const substring = url.substring(startIdx);
  // Find the index of "/"
  const endIdx = substring.indexOf("/");
  // Extract the scenario number
  return substring.substring(0, endIdx);
}
function saveSelections(e) {
  e.preventDefault();
  const formElement = document.getElementById("modal-form");
  const scenarioId = exportResourceId("scenarios");
  const districtId = exportResourceId("districts");
  formElement.action = `/districts/${districtId}/scenarios/${scenarioId}/import_from_b?source_scenario=${scenarioSelection.value}`;

  formElement.submit();
}
</script>

<style>
/* Add any additional styling here */
</style>
<style scoped>
.popup-window {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.window-container {
  height: 400px;
}

.window-controls {
  margin-top: 10px;
  text-align: right;
}
</style>
