let buildingSelectInput, clientSelectInput, userSelectInput
let buildingContainer, clientContainer

export default function accessRulesForm() {
    buildingSelectInput = $("#access_rule_building_id")
    clientSelectInput = $("#access_rule_client_id")
    userSelectInput = $("#access_rule_user_id")
    buildingContainer = $(".access_rule_building")
    clientContainer = $(".access_rule_client")
    hideEmptyValues()
    initializeForm()
    addFormListeners()
}

function initializeForm() {
    const userGroup = $("#access_rule_user_group").val();

    const selectedClientId = clientSelectInput.find('option:selected').text();
    buildingSelectInput.find('option[data-key !="' + selectedClientId + '"]').hide()

    const selectedUserId = userSelectInput.find('option:selected').text();
    clientSelectInput.find('option:contains(' + selectedUserId + ')').hide()

    onUserGroupChange(false, userGroup)
}

function addFormListeners() {
    $(document).ready(function () {

        $("#access_rule_user_group").on('change', function () {
            const userGroup = $("#access_rule_user_group").val();
            onUserGroupChange(true, userGroup)
        })

        userSelectInput.on('change', function () {
            clientSelectInput.val(null)
            $(clientSelectInput).find("option").show();
            const selectedUserId = $(this).find('option:selected').text();
            clientSelectInput.find('option:contains(' + selectedUserId + ')').hide()
        })

        clientSelectInput.on('change', function () {
            buildingSelectInput.val(null)
            $(buildingSelectInput).find("option").show()
            const selectedUserId = $(this).find('option:selected').text();
            buildingSelectInput.find('option[data-key !="' + selectedUserId + '"]').hide()
        })
    })
}

function handleFields(testCase) {
    switch (testCase) {
        case "users":
            userSelectInput.attr("disabled", false);
            clientContainer.attr("hidden", false);
            break;
        case "clear":
            clientContainer.attr("hidden", true)
            buildingContainer.attr("hidden", true);
            break;
    }
}

function clearFields(part) {
    switch (part) {
        case "all":
            buildingSelectInput.val(null);
            clientSelectInput.val(null);
            userSelectInput.val(null);
            break;
        case "admin":
            buildingSelectInput.val(null);
            clientSelectInput.val(null);
            break;
        case "partial":
            buildingSelectInput.val(null);
            break;
    }
}

function onUserGroupChange(state, userGroup) {
    if (userGroup === '5' || userGroup === '4') {
        buildingContainer.attr("hidden", false);
        if (state) {
            buildingSelectInput.val(null);
        }
        handleFields("users")
    } else if (userGroup === '1') {
        userSelectInput.attr("disabled", false);
        clearFields("admin")
        handleFields("clear")
    } else if (userGroup === '') {
        userSelectInput.attr("disabled", true);
        clearFields("all")
        handleFields("clear")
    } else {
        buildingContainer.attr("hidden", true);
        clearFields("partial")
        handleFields("users")
    }

}

function hideEmptyValues() {
    buildingSelectInput.find('option').eq(0).hide()
    clientSelectInput.find('option').eq(0).hide()
    userSelectInput.find('option').eq(0).hide()
}