import consumer from './consumer'
import {Chart} from "chart.js";
import {dynamicAdd, getChartFromId, getRandom, getTimeString} from "../pages/lca/addDataToChart";

console.log("Loading real_time_demo_channel.js");

let year = 6;

consumer.subscriptions.create({ channel: 'RealTimeDemoChannel' }, {
    connected() {
        console.log("Connected to the chat channel.");
    },

    received(data) {
        const body = JSON.parse(data.body);
        const value = body.measurement[0].value;

        const timestamp = body.measurement[0].timestamp;
        const chart = getChartFromId(`chart-${body.iid}`);

        dynamicAdd(chart, getTimeString(timestamp), value, null);
        year++;
    }
})