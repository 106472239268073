// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//require("@rails/ujs").start()
//import "styles/application.scss"
//require("@rails/ujs").start()
import 'bootstrap';
//import "packs/styles/application.scss";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import autoHide from '../pages/auto_hide';

// import testPage from '../pages/home/test_page.js';

import slideShow from '../modules/slideShow';
import maps from '../pages/maps/index';
import initializeNotifications from '../pages/notifications/index';
import notificationSubscription from '../pages/notifications/userSubscription';
import loadBuildingScripts from '../pages/building/javascript_loader'

import userDashboard from '../pages/users/dashboard'
import {autoDismissAlert} from "../pages/alert_auto_dismiss";
import {keepTabs} from "../pages/tab_keeper";
import "channels"

require("@rails/ujs").start()
require('turbolinks').start()
require("@rails/activestorage").start()
require("@rails/actioncable")
require("chartkick")
require("chartjs-plugin-datalabels")
require("chart.js")

// From https://rubyyagi.com/how-to-use-bootstrap-and-jquery-in-rails-6-with-webpacker/
var jQuery = require('jquery')
require("cocoon")
// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import { searchItems } from "../pages/dashboard_searcher";
import {
  sortByAttribute
} from "../pages/dashboard_sorter";
import { FocusViewManager } from "../pages/lca/focusViewManager";
import create_vue_app from "../utils/create_vue_app";
import ChartLoader from "../vue/charts/ChartLoader.vue";

document.addEventListener('turbolinks:load', () => {

  const pathname = window.location.pathname
  loadBuildingScripts(pathname)

  // if (window.location.pathname == "/test_page") testPage();
  if (pathname.includes('graphs')) maps();
  else if (pathname.includes('notifications')) initializeNotifications();
  else if (pathname === '/dashboard') {
    userDashboard()
    const searchBuildingsElements = document.getElementsByClassName('search-bar')
    for(let i = 0; i < searchBuildingsElements.length; i++) {
      // On page reload reset the query.
      const currentSearchBar = searchBuildingsElements[i]
      currentSearchBar.querySelector('input').value = ''
      currentSearchBar.oninput = searchItems

      const sortButtons = currentSearchBar.getElementsByClassName('sorter-btn')
      for(let j = 0; j < sortButtons.length; j++) {
        sortButtons[j].onclick = sortByAttribute
      }
    }
  }
  // Stupid code to reset the user dashboard selected menu item.
  // Check if its under /buildings/:id or /client/client-name/buildings/:id
  const underBuildings = pathname.match(/\/buildings\/\d+/);
  const underDistricts = pathname.match(/\/districts\/\d+/);
  const underClient = pathname.match(
    /\/client\/(([a-z]+)-)+[a-z]+\/building\/\d+/
  );
  const underDistrictClient = pathname.match(
    /\/client\/(([a-z]+)-)+[a-z]+\/district\/\d+/
  );
  const underManagedClientBuildings = pathname.match(/\/client\/\d+\/buildings/); // New case
  if (
    pathname !== "/dashboard" &&
    !underBuildings &&
    !underClient &&
    !underDistricts &&
    !underDistrictClient &&
    !underManagedClientBuildings 
  ) {
    localStorage.removeItem("tagSelected");
  }

  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()

  $(".hBack").on("click", function(e) {
    e.preventDefault();
    window.history.back();
  });

  $('.file-download').on('click', function(e) {
    e.preventDefault()
    window.open($(this).data('href'))
    window.focus()
  })

  $(".spinner-on-click").on("click", function() {
    $(this).html(
      '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
    );
    $(this).trigger("blur");
  });

  autoHide();
  slideShow();

  notificationSubscription();

  autoDismissAlert();

  if (pathname.includes('lca_analysis')) {
    const focusGroups = document.getElementsByClassName('focus-group');
    for (let i = 0; i < focusGroups.length; i++)
      new FocusViewManager(focusGroups[i]);
  }

  if (pathname.includes('real_time_demo')) {
    const chartApps = document.querySelectorAll('[id^="test_vue_id_"]');
    for (let i = 0; i< chartApps.length; i++) {
      create_vue_app(chartApps[i].id, ChartLoader);
    }
  }

  if(pathname.includes('district')) {
    keepTabs();
  }
})
