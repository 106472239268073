/**
 * Responsible for dismissing automatically the alert with class `alert-custom`.
 * Sets a timer for 10 secs, and then removes the window with a smooth fade-out animation.
 * The alert can also be closed manually. Though, whenever the user hovers over the alert window,
 * the timer stops temporarily, and only continues to count whenever the user stops hovering.
 * @todo I noticed that there are some functions inside the exported function. You could add 1-2 lines for them too
 *       above their definition (even though their functionality is clear).
 */
export function autoDismissAlert()
{
    var currentChunk = 0;
    var chunks = 5;

    var xButton = document.getElementById('dismissAlert');

    if (xButton !== null) {
        xButton.onclick = manualDismiss;
    }

    $('#autoDismissAlert').hover(function() {
        window.clearInterval(timer);
        window.clearTimeout(time);
    }, function() {
        timer = setInterval(function() {
            update();
        }, 10);

        time = window.setTimeout(function() {
            $("#autoDismissAlert").fadeTo(1000, 0).slideUp(1000, function() {
                $(this).remove();
            });
        }, ((chunks - currentChunk) * 1000));
    });

    var timer = setInterval(function() {
        update();
    }, 10);

    var time = window.setTimeout(function() {
        $("#autoDismissAlert").fadeTo(1000, 0).slideUp(1000, function() {
            $(this).remove();
        });
    }, ((chunks - currentChunk) * 1000));

    function update() {
        currentChunk += 0.01;
        var progPercent = 100 - (currentChunk * (100 / chunks));
        $("#autoDismissAlert .progress-bar").css('width', progPercent + '%').attr('aria-valuenow', progPercent);

        if (currentChunk >= 5) {
            window.clearInterval(timer);
        }
    }

    function manualDismiss() {
        var element = document.getElementById('autoDismissAlert');
        element.classList.add('alert-fade-out');
        element.addEventListener('transitionend', () => {
            $(this).remove();
        });
    }
}

