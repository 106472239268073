<template>
  <select
    class="btn btn-info text-left mb-2"
    name="building_id"
    id="building_id"
    v-model="selectedBuilding"
  >
    <option v-for="(dataset, index) in tableData" :key="index" :value="index">
      {{ dataset.text }}
    </option>
  </select>

  <KpisTable :table-data="selectedKpisTableData" :dataset-index="selectedBuilding"></KpisTable>

  <MultipleChartCards :chartData="selectedChartData"></MultipleChartCards>

  <GeneratorsPaybackTable
    v-for="(_, index) in selectedScenarioData"
    :generators_payback="selectedScenarioData[index]"
  />
</template>

<script setup>
/*
* Component Name: LcaBuildingSectorResults
* Purpose: This component is used in LCA results page for districts in the "Building Sector" tab. It is used
*          to provide dynamic tables and charts that can update their data to display "per-building" results instead
*          of showing just the total values.
*
* Props:
*  - tableData (Array, optional): An array that contains the datasets of KPIs for the component `KpisTable` for each
*                                 building, pair of buildings or total values. For more details on the schema of this
*                                 prop check the documentation in `KpisTable` component.
*                                 @todo Simplify the schema of this variable, it includes both the options for select
*                                       and the data. It should be divided into two props.
*  - chartData (Array, optional): An array that contains the datasets that will be displayed in the charts of the
*                                 `MultipleChartCards` component for each building, pair of buildings or total values.
*                                 For more details on the schema of this prop check the documentation in `KpisTable` component.
*  - generatorsPayback(Array, optional): An array that contains the datasets that will be displayed in the tables of the
*                                       `GeneratorsPaybackTable` component for each building, pair of buildings or total values.
*                                       For more details on the schema of this prop check the documentation in `KpisTable` component.
*/
import { ref, computed } from "vue";
import KpisTable from "./kpis_table.vue";
import GeneratorsPaybackTable from "./generators_payback_table.vue";
import MultipleChartCards from "./multiple_chart_cards.vue";

const props = defineProps({
  tableData: {
    type: Array,
    default: () => [],
  },
  chartData: {
    type: Array,
    default: () => [],
  },
  generatorsPayback: {
    type: Array,
    default: () => [],
  },
});

/* The currently selected building, i.e. the id of the building based on the selected option of the buildings dropdown. */
const selectedBuilding = ref(0);

/*
 * Based on the `selectedBuilding` it extracts the corresponding dataset that will be used in `KpisTable` component.
 */
const selectedKpisTableData = computed(() => {
  return props.tableData[selectedBuilding.value].data.total;
});

/*
 * Based on the `selectedBuilding` it extracts the corresponding dataset that will be used in `MultipleChartCards` component.
 */
const selectedChartData = computed(() => {
  return props.chartData[selectedBuilding.value];
});

/*
 * Based on the `selectedBuilding` it extracts the corresponding dataset that will be used in `GeneratorsPaybackTable` component.
 */
const selectedScenarioData = computed(() => {
  return props.generatorsPayback[selectedBuilding.value];
});
</script>

