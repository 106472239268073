<template>
  <AutomaticForm title="Data source info" :form-fields="props.formFields" prefix="data_source"
                 :db-object="dbObject"></AutomaticForm>
</template>

<script setup>
import AutomaticForm from "../shared/AutomaticForm.vue";
const props = defineProps({
  formFields: {
    type: Array,
    required: true
  },
  dbObject: {
    type: Object,
    required: true
  }
})
</script>

<style scoped>

</style>