<!-- v-if="isRowExpanded(component.id)" -->
<template>
  <div>
    <div class="d-flex justify-content-between mb-4">
      <!-- Filters Section -->
        <div class="flex-column">
          <label for="sheetType">Filter by Sheet Type:</label>
          <br/>
          <div class="btn-group">
            <button class="btn btn-primary rounded-lg  ml-2" id="filterDropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
              <i class="fa fa-filter mr-2" />
              {{ selectedSheetType === '' ? 'All' : selectedSheetType }}
              <i class="fa fa-angle-down ml-3" />
            </button>
            <div class="dropdown-menu" aria-labelledby="filterDropdown" id="filter-dropdown">
              <button class="dropdown-item bg-sort-selected" value=""
                      type="button" @click="selectedSheetType = 'All'" >
                All
                <i class="fa fa-check"></i>
              </button>
              <button v-for="type in uniqueSheetTypes.sort()" :key="type" :value="type"
              class="dropdown-item" type="button" @click="selectedSheetType = type" >
                {{ type }}
              </button>
            </div>
          </div>
        </div>

        <div class="flex-column mx-5">
          <label for="isMainInventory">Inventory Filter:</label>
          <div class="radio-group">
            <input
                type="radio"
                id="allInventory"
                value=""
                v-model="isMainInventory"
            />
            <label for="allInventory">All</label>

            <input
                type="radio"
                id="mainInventory"
                value="true"
                v-model="isMainInventory"
            />
            <label for="mainInventory">Main Inventory</label>

            <input
                type="radio"
                id="customInventory"
                value="false"
                v-model="isMainInventory"
            />
            <label for="customInventory">Custom</label>
          </div>
        </div>

        <div class="flex-column mx-3 search-bar">
          <label for="search">Search all LCI Components:</label>
          <div class="input-group">
            <input v-model="search" type="search" id="search" placeholder="Enter Component Name…" class="form-control" />
            <button type="button" class="btn btn-primary">
              <i class="fa fa-search"></i>
            </button>
          </div>

        </div>

      <div class="flex-column ml-auto align-content-end">
        <a class="btn btn-primary rounded-pill" href="/lci_components/new">
          <i class="fa fa-plus-circle" /> New LCI Component
        </a>
      </div>
    </div>

    <div id="app">
      <div class="col-md-12">
        <div class="panel panel-default">
          <div class="panel-body">
            <table class="table" style="width: 100%">
              <thead class="thead-primary">
                <tr>
                  <th style="border-right: 0" v-if="isMainInventory !== 'false'"></th>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Subtype</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <!-- Main component row with toggle functionality -->
                <template v-for="component in paginatedComponents" :key="component.id">
                  <tr :class="{ 'simapro-line': isSimaproRun(component), 'custom-component-line': isDefaultComponent(component) }">
                    <td v-if="isMainInventory !== 'false'">
                      <button
                          v-if="hasSimaproChild(component) && !component.vcomponent_id && component.IS_MAIN_INVENTORY"
                        @click="toggleRow(component.id)"
                        :aria-expanded="isRowExpanded(component.id).toString()"
                        :class="`btn btn-sm btn-primary ${isRowExpanded(component.id) ? 'fa fa-angle-down font-weight-bold' : 'fa fa-angle-right font-weight-bold' } `"
                        style="width: 30px; height: 30px"
                      />
                    </td>
                    <td>{{ component.id.value }}</td>
                    <td>
                      {{ isSimaproRun(component) ? `${component.fu_quantity.value} ${component.fu_measurement_unit.value}` : component.name.value }}
                      <i v-if="displayIconFa(component)" class="ml-1 mr-2 fa fa-home"></i>
                      <span v-if="isDefaultComponent(component)" class="badge badge-secondary ml-1 px-2">Custom</span>
                    </td>
                    <td>{{ component.component_type.value }}</td>
                    <td v-if="!isSimaproRun(component)">{{ component.component_subtype ? component.component_subtype.value : '-' }}</td>
                    <td>
                      <button @click="openModal(component)" class="btn btn-sm btn-link">Details</button><span v-if="component.edit_path">| </span>
                      <a :href="component.edit_path" v-if="component.edit_path">Edit</a> <span v-if="component.delete_path">| </span>
                      <a :href="component.delete_path" data-method="delete" data-confirm="Are you sure?" v-if="component.delete_path">Delete</a>
                    </td>
                  </tr>

                  <!-- Simapro runs as expandable rows -->
                  <tr
                    :class="{ 'simapro-line': isSimaproRun(simapro) }"
                    v-if="isRowExpanded(component.id)"
                    :key="`expand-${component.id}`"
                    v-for="simapro in component.simapro_runs"
                  >
                    <td></td>
                    <td>{{ simapro.id.value }}</td>
                    <td>{{ simapro.fu_quantity.value }} {{simapro.fu_measurement_unit.value}}</td>
                    <td colspan="2">{{ new Date(simapro.date.value).toLocaleDateString('en-UK') }}</td>
                    <!-- <td>{{ component.component_subtype }}</td> -->
                    <td>
                      <button @click="openModal(simapro, 'simapro')" class="btn btn-sm btn-link">Details</button><span v-if="simapro.edit_path">| </span>
                      <a :href="simapro.edit_path" v-if="simapro.edit_path">Edit</a> <span v-if="simapro.delete_path">| </span>
                      <a :href="simapro.delete_path" data-method="delete" data-confirm="Are you sure?" v-if="simapro.delete_path">Delete</a>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination-controls">
      <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
    </div>

    <ComponentModal
      :component="selectedComponent"
      :simaproRuns="simaproRuns"
      :show="showModal"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { ref, computed } from "vue";
import ComponentModal from "./LciComponentModal.vue";

export default {
  props: {
    components: {
      type: Array,
      required: false,
    },
  },
  components: {
    ComponentModal,
  },
  setup({ components }) {
    const selectedSheetType = ref("");
    const isMainInventory = ref("");
    const showModal = ref(false);
    const selectedComponent = ref({});
    const expandedRows = ref(new Set());
    const componentsList = components;
    const simaproRuns = ref([]);
    const search = ref('');

    const currentPage = ref(1);
    const itemsPerPage = 10;

    const totalPages = computed(() => Math.ceil(filteredComponents.value.length / itemsPerPage));

    const paginatedComponents = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      return filteredComponents.value.slice(start, end);
    });

    /**
     * Filters and sorts components based on selected sheet type and inventory status,
     * grouping them by their vcomponent_id.
     *
     * @return [Array] a flat array of components sorted by their IDs.
     */
    const filteredComponents = computed(() => {
      let filtered = componentsList;

      if (selectedSheetType.value) {

        if (selectedSheetType.value === 'All') {
          tickCorrectFilter('');
        } else {
          filtered = filtered.filter(
              (comp) => comp.SHEET_TYPE.value === selectedSheetType.value
          );

          tickCorrectFilter(selectedSheetType.value);
        }
      }

      if (search.value) {
        filtered = filtered.filter(
            (comp) => comp.name.value.toLowerCase().includes(search.value.toLowerCase())
        );
      }

      if (isMainInventory.value === 'true') {
        filtered = filtered.filter(
          (comp) => comp.hasOwnProperty('IS_MAIN_INVENTORY') && comp.IS_MAIN_INVENTORY
        );
      } else if (isMainInventory.value === 'false') {
        filtered = filtered.filter(
          (comp) => comp.hasOwnProperty('IS_MAIN_INVENTORY') && !comp.IS_MAIN_INVENTORY
        );
      }

      const groupedComponents = filtered.reduce((groups, component) => {
        if (!component.vcomponent_id) {
          groups.push([component]);
        } else {
          const mainComponentGroup = groups.find(
            (group) => group[0].id.value === component.vcomponent_id.value
          );

          if (mainComponentGroup) {
            mainComponentGroup.push(component);
          } else {
            const mainComponent = filtered.find(
              (comp) => comp.id.value === component.vcomponent_id.value
            );
            if (mainComponent) {
              groups.push([mainComponent, component]);
            }
          }
        }
        return groups;
      }, []);

      groupedComponents.sort((a, b) => a[0].id.value - b[0].id.value);
      currentPage.value = 1

      return groupedComponents.flat();
    });

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value += 1;
      }
    };
    
    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value -= 1;
      }
    };

    /**
     * Computes unique sheet types from the components.
     *
     * @return [Array] an array of unique sheet types.
     */
    const uniqueSheetTypes = computed(() => {
      return [...new Set(components.map((comp) => comp.SHEET_TYPE.value))];
    });


    /**
     * Applies the correct background to the selected filter button along with an fa-arrow next to it.
     * To select the 'All' filter, simply pass an empty filterValue
     *
     * @param filterValue The value as found in the "value" field of the filter buttons.
     */
    const tickCorrectFilter = (filterValue) => {
       if (filterValue === 'All')
         filterValue = '';

       const filterButtons = Array.from(document.getElementById('filter-dropdown').children);
       const selectedFilterButton = filterButtons.findLast(element => element.value === filterValue);
       const previousFilterButton = filterButtons.findLast(element => element.children.length > 0);

       previousFilterButton.removeChild(previousFilterButton.children[0]);
       previousFilterButton.classList.remove('bg-sort-selected')

       selectedFilterButton.innerHTML += " <i class='fa fa-check' />" ;
       selectedFilterButton.classList.add('bg-sort-selected');
     }

    /**
     * Toggles the expansion state of a row identified by its component ID.
     *
     * @param componentId [Number] the ID of the component whose row state will be toggled.
     * @return [void]
     */
    const toggleRow = (componentId) => {
      if (expandedRows.value.has(componentId)) {
        expandedRows.value.delete(componentId);
      } else {
        expandedRows.value.add(componentId);
      }
    };

    const displayIconFa = (component) => {
      if (component.IS_B_COMPONENT === undefined) {
        return false;
      }

      return component.IS_B_COMPONENT;
    }

    /**
     * Checks if a row is expanded based on the component ID.
     *
     * @param componentId [Number] the ID of the component to check.
     * @return [Boolean] true if the row is expanded, false otherwise.
     */
    const isRowExpanded = (componentId) => {
      return expandedRows.value.has(componentId);
    };

    /**
     * Determines if a component is a Simapro run by checking for vcomponent_id.
     *
     * @param component [Object] the component to check.
     * @return [Boolean] true if it has a vcomponent_id, false otherwise.
     */
    const isSimaproRun = (component) => {
      return (
        component.vcomponent_id !== null &&
        component.vcomponent_id !== undefined
      );
    };

    const isDefaultComponent = (component) => {
      if (component.IS_MAIN_INVENTORY === undefined)
        return true;

      return !component.IS_MAIN_INVENTORY && !isSimaproRun(component);
    }



    /**
     * Determines if a row should be shown based on its Simapro run status.
     *
     * @param component [Object] the component to evaluate.
     * @return [Boolean] true if the row should be shown, false otherwise.
     */
    function shouldShowRow(component) {
      if (isSimaproRun(component)) {
        return isRowExpanded(component.vcomponent_id.value);
      } else {
        return true;
      }
    }

    /**
     * Checks if a component has any Simapro children.
     *
     * @param component [Object] the parent component to check.
     * @return [Boolean] true if it has Simapro children, false otherwise.
     */
    function hasSimaproChild(component) {
      return component.simapro_runs && component.simapro_runs.length > 0;
    }

    /**
     * Retrieves Simapro run components based on the parent component ID.
     *
     * @param componentId [Number] the ID of the parent component.
     * @return [Array] an array of Simapro run components.
     */
    function getSimaproRunComponents(component) {
      return component.simapro_runs;
    }

    /**
     * Opens a modal with the selected component for further actions.
     *
     * @param component [Object] the component to display in the modal.
     * @return [void]
     */
     const openModal = (component, method='default') => {
      selectedComponent.value = component;

      if (method === 'default') {
        simaproRuns.value = getSimaproRunComponents(component); // Assign to the separate ref
      } else {
        simaproRuns.value = []
      }

      showModal.value = true;
    };

    /**
     * Closes the modal and resets the selected component.
     *
     * @return [void]
     */
    const closeModal = () => {
      showModal.value = false;
      selectedComponent.value = {};
      simaproRuns.value = [];
    };

    /**
     * Handles the destruction of a component (delete logic).
     *
     * @param component [Object] the component to be destroyed.
     * @return [void]
     */
    const destroyComponent = (component) => {
      console.log("Destroy component", component);
    };

    return {
      selectedSheetType,
      isMainInventory,
      showModal,
      selectedComponent,
      simaproRuns,
      filteredComponents,
      uniqueSheetTypes,
      search,
      toggleRow,
      isRowExpanded,
      isDefaultComponent,
      getSimaproRunComponents,
      displayIconFa,
      openModal,
      closeModal,
      destroyComponent,
      isSimaproRun: isSimaproRun,
      shouldShowRow,
      hasSimaproChild,
      paginatedComponents,
      currentPage,
      itemsPerPage,
      totalPages,
      nextPage,
      prevPage,
    };
  },
};
</script>

<style scoped>
th,
td {
  border: 1px solid #ddd;
  padding: 8px;
}

.filters {
  margin-bottom: 20px;
}
/* Container for select dropdown */
select {
  appearance: none; /* Remove default select arrow for custom styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #c4c4c4;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  width: 130px;
  position: relative;
  background-size: 12px;
  color: #fff;
  background-color: #166aa3;
  border-color: #166aa3;
  text-align: center;
}

.checkbox {
  text: red;
}

/* Dropdown options styling */
select option {
  padding: 10px;
  font-size: 14px;
  color: #333333;
  background-color: #ffffff;
}

/* Hover and focus states */
select:focus {
  outline: none; /* Remove the default outline */
  border-color: #007bff; /* Border color on focus */
}

select:hover {
  border-color: #007bff; /* Border color on hover */
}

.simapro-line {
  background: linear-gradient(to right, #e5fff4, #f0f8ff);
}

.custom-component-line {
  background: linear-gradient(to right, #fffff3, #ffffe7) ;
}

.radio-group input[type="radio"] {
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #166aa3;
  outline: none;
  cursor: pointer;
  margin-right: 5px;
  position: relative;
}

.radio-group input[type="radio"]:checked::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #166aa3;
  position: absolute;
  top: 1px;
  left: 1px;
}
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-controls button {
  padding: 6px 12px;
  margin: 0 10px;
  border: 1px solid #166aa3;
  background-color: #166aa3;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.pagination-controls button:disabled {
  background-color: #c4c4c4;
  cursor: not-allowed;
}

</style>
