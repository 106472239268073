/**
 * Takes a group of elements inside a `col` and makes them larger, with the ability to scroll through them.
 *
 * In order to use this class, some prerequisite elements must exist in the HTML page. Before reading the
 * prerequisites, **make sure that the items you want to be focused are defined twice inside the page**
 * you plan to use this capability. Ideally, you might want to make the "unfocused" items small
 * (by using a bootstrap class like `col-4`) and the "focusable" items big (bootstrap class `col-8` or
 * `col-10`). Make sure that the "focusable" items are invisible initially (by using the `d-none` bootstrap
 * class).
 *
 * ## Prerequisites
 * First, there must be an HTML element (a div ideally) that contains all the elements, which
 * can be focused. **This element is passed through the constructor's parameters.**
 *
 * Second, inside the focus group, there must be these classes included:
 * + `focused-items` the group that contains the focus items.
 * + `focus-item` each item that can be focused.
 * + `unfocused-items` the group that contains the items that cannot be focused.
 * + `focus-button` once, the button that toggles focus mode.
 * + `btn-prev-item` once, the button that scrolls to the next focus item when focus mode is enabled.
 * + `btn-next-item` once, the button that scrolls to the previous item when focus mode is enabled..
 *
 * @param focusGroup The HTML element that contains the focused and unfocused items.
 */
export class FocusViewManager {

    constructor(focusGroup) {
        // variables that need starting point.
        this.isFocused = false;
        this.index = 1;

        // HTML Element that gets passed by the constructor.
        this.focusGroupElement = focusGroup;
        this.graphCount = this.focusGroupElement.getElementsByClassName('focus-item').length;

        // focus button that enables/disables the focus mode.
        this.focusButton = this.focusGroupElement.getElementsByClassName('focus-button')[0];
        this.focusButton.addEventListener('click', () => this.toggleFocus());

        // right and left buttons (next and prev).
        this.focusGroupElement.getElementsByClassName('btn-prev-item')[0].addEventListener('click', () => this.previousChart());
        this.focusGroupElement.getElementsByClassName('btn-next-item')[0].addEventListener('click', () => this.nextChart());

        // get all the divs that correspond to the focused and unfocused items.
        this.unfocusedItems = this.focusGroupElement.getElementsByClassName('unfocused-items')[0];
        this.focusedItems = this.focusGroupElement.getElementsByClassName('focused-items')[0];
    }

    /**
     * Inverts the value of {@link isFocused}. Then, based on the new value, it
     * enables or disables focus mode.
     */
    toggleFocus() {
        this.isFocused = !this.isFocused;

        if (this.isFocused) {
            this.focusElements()
        } else {
            this.unfocusElements()
        }
    }

    /**
     * Enables focus mode. Hides all the unfocused items from the view and displays the element that contains
     * the focusable items. When enabling focus mode the first focusable element will be focused
     * {check @link updateGraphVisibility}.
     *
     * Also, makes the Focus Mode button have the 'ON' text in it.
     */
    focusElements() {
        // update the graph's visibility, which simply displays one graph.
        this.updateGraphVisibility();

        // the green focus mode button becomes ON
        this.focusButton.classList.remove('btn-outline-success');
        this.focusButton.classList.add('btn-success');
        this.focusButton.innerHTML = 'Focus Mode: ON';

        // hide the unfocused items.
        this.unfocusedItems.classList.add('d-none');

        // and show the focusable items.
        this.focusedItems.classList.remove('d-none');
        this.focusedItems.classList.add('d-lg-flex');
    }

    /**
     * It disables the focus mode by hiding the focusable items group and displaying the unfocused items group.
     * Its functionality is the opposite of {@link focusElements} method.
     *
     * Also, makes the Focus Mode button have the 'OFF' text in it.
     */
    unfocusElements() {
        // the green focus mode button becomes OFF
        this.focusButton.className = 'btn btn-outline-success';
        this.focusButton.innerHTML = 'Focus Mode: OFF';

        // the unfocused items show.
        this.unfocusedItems.classList.remove('d-none');

        // and the focused items hide.
        this.focusedItems.classList.remove('d-lg-flex');
        this.focusedItems.classList.add('d-none');
    }

    /**
     * Adds +1 to the {@link index} value and updates the focused item that is currently displayed.
     * If the index is on the maximum value, it is reverted back to 1.
     */
    nextChart() {
        if (this.index === this.graphCount)
            this.index = 1;
        else
            this.index++;

        this.updateGraphVisibility();
    }

    /**
     * Subtracts 1 from the {@link index} value and and updates the focused item that is currently displayed.
     * If the index is 1, it is reverted back to 1.
     */
    previousChart() {
        if (this.index === 1)
            this.index = this.graphCount;
        else
            this.index--;

        this.updateGraphVisibility();
    }

    /**
     * Displays the current focused element based on the {@link index} variable's value. Cycles through
     * all elements that have the `focus-item` class in them. Then, based on the {@link index}'s value
     * it either removes or adds the `d-none` class. This is usually called after
     * the "Next" or "Previous" button is pressed, or when the focus mode is enabled.
     */
    updateGraphVisibility() {
        // get all the focusable items.
        const focusItems = this.focusGroupElement.getElementsByClassName('focus-item');

        // and if the focus mode is on, this will show the correct graph/focus-item.
        for (let i = 0; i < focusItems.length; i++) {
            if (this.index - 1 === i) {
                focusItems[i].classList.remove('d-none')
            } else {
                focusItems[i].classList.add('d-none')
            }
        }
    }
}