/**
 * ## Description
 * Sorts the building or district table's elements, based on the column selected by the user.
 * At first it selects the correct table and its rows.
 * Then it sorts them accordingly (by id, name or country) and puts them in an array.
 * And, finally, it deletes all the rows of the table and re-adds the elements from
 * the array to the table.
 *
 * ## How It Works
 * In order to sort the items, it searches all elements inside the `searchable` table, that have the
 * `search-item` in them. Then the table elements are stored inside an Array, in which the {@link sort()}
 * method is overridden (based on the attribute to be sorted) and then called.
 *
 */
export function sortByAttribute() {
    setDropDownTick(this);
    sortTableElements(this);
}

/**
 * Performs the sorting method inside the table element. Depending on the value of `button`, it sorts the items
 * in an ascending order. Finds the table that `button` belongs to (to locate the `searchable` items)
 * and then performs the sorting.
 * @param button The button that contains the sort attribute.
 */
function sortTableElements(button) {
    const method = button.dataset['sort']
    const searchBar = button.closest('.search-bar')
    const table = searchBar.parentElement.querySelector('.searchable')

    // table spot indexes (they might change in the future)
    // WARNING: Unsafe parsing. For example in case we translate the site to another language.
    const headersParent = table.getElementsByTagName('tr')[1]
    const headers = Array.from(headersParent.getElementsByTagName('th'))
    const nameIndex = headers.findIndex(e => e.textContent === 'Name');
    const idIndex = headers.findIndex(e => e.textContent === '#');
    const countryIndex = headers.findIndex(e => e.textContent === 'Location')

    // get the necessary table elements (body + <tr> elements).
    const tableBody = table.querySelector('tbody')
    const tableData = Array.from(tableBody.getElementsByClassName('search-item'));

    // based on the sorting method, call sort() accordingly.
    switch (method) {
        case "name":
            tableData.sort((a, b) => a.children.item(nameIndex).textContent.localeCompare(b.children.item(nameIndex).textContent));
            break;
        case "id":
            tableData.sort((a, b) => a.children.item(idIndex).textContent - b.children.item(idIndex).textContent);
            break;
        case "country":
            tableData.sort((a, b) => a.children.item(countryIndex).textContent.localeCompare(b.children.item(countryIndex).textContent));
            break;
        default:
            break;
    }

    // remove all <tr> elements and add the sorted ones.
    tableBody.innerHTML = '';
    tableData.forEach(node => tableBody.appendChild(node));
}


/**
 * Sets a font-awesome tick icon right next to the attribute that the elements got sorted by.
 * Also, it changes the attribute's background color to teal.
 * @param button The button that corresponds to the attribute that sorts the elements.
 */
function setDropDownTick(button) {

    // select the buttons based on the district value.
    const parentDropdownElement = button.parentElement
    const buttons = parentDropdownElement.getElementsByClassName('sorter-btn');

    // disable all drop-down buttons.

    for (let i = 0; i < buttons.length; i++) {
        // to disable the buttons, delete the 'bg-sort-selected'
        if (buttons[i].className.includes("bg-sort-selected")) {
            buttons[i].classList.remove("bg-sort-selected")

            // and also delete the <i> tag with the check mark.
            if (buttons[i].childNodes.length === 2) {
                buttons[i].childNodes.item(1).remove();
            }
        }
    }

    // then selected the correct button (which is passed as a function variable)
    button.classList.add('bg-sort-selected')

    // then add to it the <i> tag with the check mark.
    const iElement = document.createElement("i");
    iElement.className = "fa fa-check"
    button.appendChild(iElement);
}