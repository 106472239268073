<template>
  <ul
      class="nav nav-pills mb-3 bg-white p-2 rounded shadow-sm"
      id="pills-tab"
      role="tablist"
  >
    <li class="nav-item" v-for="(tab, index) in tabs" :key="index">
      <a
          class="nav-link"
          :class="{ active: isTabActive(tab.key) }"
          :id="'pills-' + tab.key + '-tab'"
          data-toggle="pill"
          :href="'#pills-' + tab.key"
          role="tab"
          :aria-controls="'pills-' + tab.key"
          :aria-selected="index === 0"
          @click="updateLastSelectedItem(tab.key)"
      >{{ tab.label }}</a
      >
    </li>
  </ul>
  <div class="tab-content" id="pills-tabContent">
    <div
        class="tab-pane fade"
        :class="{ 'show active': isTabActive(tab.key) }"
        v-for="(tab, index) in tabs"
        :key="index"
        :id="'pills-' + tab.key"
        role="tabpanel"
        :aria-labelledby="'pills-' + tab.key + '-tab'"
    >
      <div class="alert alert-warning p-1 mb-2" role="alert">
        <h3><b>Notice:</b></h3>
        <ul class="m-0 p-1 list-unstyled">
          <li v-for="warning in globalWarnings">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            {{ warning }}
          </li>
          <li v-for="warning in warnings[tab.key]">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            {{ warning }}
          </li>
        </ul>
      </div>
      <NestedAutomaticForm
          :db-objects="tab.dbObjects"
          :form-fields="tab.formFields"
          :isDistrict="tab.isDistrict"
          :title="tab.formTitle"
          :prefix="'scenario[' + tab.prefix + ']'"
          :associations="tab.associations"
          :max-nested-forms="tab.maxNestedForms"
          :start-with="tab.startWith"
          @valid="onValid"
      ></NestedAutomaticForm>
    </div>
  </div>
  <input type="hidden" name="selectedTab" v-model="lastTab"/>
</template>

<script setup>
import NestedAutomaticForm from "../shared/NestedAutomaticForm.vue";
import {ref} from "vue";

const props = defineProps({
  districtPlantFormFields: {
    type: Array,
    required: true,
  },
  districtPlantDbObjects: {
    type: Array,
    required: false,
    default: [],
  },
  districtEssFormFields: {
    type: Array,
    required: true,
  },
  districtEssDbObjects: {
    type: Array,
    required: false,
    default: [],
  },
  infrastructureFormFields: {
    type: Array,
    required: true,
  },
  infrastructureDbObjects: {
    type: Array,
    required: false,
    default: [],
  },
  vehicleFormFields: {
    type: Array,
    required: true,
  },
  vehicleDbObjects: {
    type: Array,
    required: false,
    default: [],
  },
  currentTab: {
    type: String,
    required: false,
    default: null,
  },
  isDistrict: {
    type: Boolean,
  }
})

const isValid = ref(false);

function onValid(e) {
  isValid.value = isValid.value && e;
}

const tabs = [
  {
    key: "district_plant",
    label: "District Plants",
    formTitle: "District Plant",
    prefix: "district_plants_attributes",
    isDistrict: true,
    dbObjects: props.districtPlantDbObjects,
    formFields: props.districtPlantFormFields,
  },
  {
    key: "district_ess",
    label: "District ESS",
    formTitle: "District ESS",
    prefix: "district_ess_attributes",
    isDistrict: true,
    dbObjects: props.districtEssDbObjects,
    formFields: props.districtEssFormFields,
  },
  {
    key: "vehicle",
    label: "Vehicles",
    formTitle: "Vehicle",
    prefix: "vehicles_attributes",
    isDistrict: true,
    dbObjects: props.vehicleDbObjects,
    formFields: props.vehicleFormFields,
  },
  {
    key: "infrastructure",
    label: "Infrastructure",
    formTitle: "Infrastructure component",
    prefix: "infrastructures_attributes",
    isDistrict: true,
    dbObjects: props.infrastructureDbObjects,
    formFields: props.infrastructureFormFields,
  },
];

const warnings = {
  connection: [
    "The periods number and durations in import and export of electricity must be the same.",
  ],
  investment: [
    "The investment form is filled with default values, please make any necessary changes considering your scenario.",
  ],
};


const globalWarnings = [
  "Installation year of components must be less than or equal to the year of the first timestamp of the timeseries.",
];

const lastTab = ref(props.currentTab ?? sessionStorage.getItem("lastTab") ?? "district_plant");

function updateLastSelectedItem(e) {
  // Adds an entry to the sessionStorage so that when user clicks "Save and continue" it starts from where it was.
  sessionStorage.setItem("lastTab", e);
  lastTab.value = e;
}

function isTabActive(tab) {
  return lastTab.value === tab;
}
</script>

<style scoped>

</style>