/**
 * ## Description
 * This is mainly used inside the "Edit Assets" or "Edit Building" page in Verify-D. It makes sure, whenever the user
 * clicks on "Save", that the active tab that gets redirected to (in the show or form page) is the same as the one
 * they were editing. E.g., if the user is on the "District Plants" page and clicks on "Save", they will then get
 * redirected to the "View Scenario" page, with the "District Plants" page already opened.
 *
 * ## How It Works
 * There are two hidden inputs, one called `last_district_asset_active_tab` and the other is called `last_district_building_active_tab`.
 * There is also a click listener assigned to every tab that there is. Then, if the tab's name corresponds to a "redirect-able" tab,
 * the hidden input's value changes and then is transferred to the server-side.
 */
export function keepTabs() {
    $(document).ready(function () {
        $('.nav-item').on('click', function () {
            // get the selected tab's name.
            const tabName = $(this)[0].textContent.toLowerCase();

            // hidden inputs for redirecting.
            let lastTabElement = document.getElementById("last_district_asset_active_tab");
            let lastTabBuildingElement = document.getElementById("last_district_building_active_tab");

            // acceptable names for the tabs.
            const districtAssetTabs = ["district plants", "district ess", "vehicles", "infrastructure"];
            const districtBuildingTabs = ["hvac", "insulation / glazing", "dhw", "photovoltaic", "wind turbine", "battery", "connection", "investment", "data source"];

            // if the tab's name corresponds to one of the acceptable names, change the hidden input's value.
            if (districtAssetTabs.includes(tabName)) {
                lastTabElement.value = tabName;
            }

            // same for the buildings.
            if (districtBuildingTabs.includes(tabName)) {
                if (tabName === "insulation / glazing") {
                    lastTabBuildingElement.value = "insulation";
                } else {
                    lastTabBuildingElement.value = tabName;
                }
            }
        });
    });
}