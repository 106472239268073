import { createApp } from 'vue'
/**
 * Generic function that initializes a Vue App when given the element to be mounted
 * and the root component. The component is usually a div element, that **must** have
 * `data-vue` in it. This is often used to pass data from the back-end into the front-end
 * (i.e. the Vue component's props).
 *
 * See 'javascript/pages/buildings/javascript_loader.js' to understand how it's called and used.
 * @param elementId The ID of the div element to be mounted.
 * @param rootComponent The imported component from 'javascript/vue'.
 */
export default function(elementId, rootComponent) {
    const mountElement = document.getElementById(elementId)
    if(mountElement) {
        const props = JSON.parse(mountElement.dataset.vue)
        mountElement.removeAttribute('data-vue')
        const app = createApp(rootComponent, props)
        app.mount(mountElement)
    }
}