export default function() {
    const dashboard = $('#user-dashboard')
    const spinner = $('#spinner')
    const buttons = $('#v-pills-tab .nav-link')

    $(buttons).on('click', function(e) {
        localStorage.setItem("tagSelected", e.target.id);
        return true;
    })

    retrieveSelected(dashboard, buttons, spinner)
}

function retrieveSelected(dashboard, buttons, spinner) {
    const selectedTag = localStorage.getItem("tagSelected");
    const element = selectedTag ? document.getElementById(selectedTag) : buttons[0]
    const contentElement = $(`#${$(element).attr('aria-controls')}`)

    $(buttons).removeClass('active')
    $('.tab-pane').removeClass('show active')

    element.classList.add("active");
    $(contentElement).addClass('active show')

    $(dashboard).show() // To avoid some weird renders after clicking the Dashboard link.
}