/**
 * Performs search inside a "searchable" table. It is used in the Dashboard both for districts' and buildings' to filter
 * the corresponding table by name. Gets the data from the search bar and hides the `<tr>` elements that don't match
 * the query.
 * @note It might be deprecated, since we might add pagination.
 * @todo Add explanation about all the elements of the searchable table.
 */
export function searchItems() {
    performSearch(this);
}

/**
 * Searches for all the `search-item` elements that are associated to the `searchBar` and
 * checks their lowercase value with the query string of the `searchBar`. If the strings of
 * a `search-item` and the query match, then the item is displayed in the `searchable` table.
 * Otherwise, it is hidden. This method accesses only the elements related to the provided `searchBar`.
 *
 * #### Future Addition
 * Add the ability to highlight the text found.
 * @param searchBar The search bar where the query is provided.
 */
function performSearch(searchBar)
{
    // initialize class names that correspond to the correct data.
    // Find the next searchable table, the one that is intended for the selected search bar.
    const table = searchBar.parentElement.querySelector('.searchable')
    const className = 'search-item'
    const searchInputField = searchBar.querySelector('input')

    // get elements from html for the query (buildings + the query)
    const elements = table.getElementsByClassName(className);
    const searchQuery = searchInputField.value.toLowerCase();
    // for each one of the buildings test the query
    for (let i = 0; i < elements.length; i++) {
        const buildingName = elements[i].children.item(2).innerHTML;

        // if the query succeeds, modify the class name to be as it was.
        if (buildingName.toLowerCase().includes(searchQuery)) {
            elements[i].className = "table-light " + className;
        }

        // if the query doesn't succeed, add the 'd-none' value that hides the element from the table.
        else {
            elements[i].className = "table-light " + className + " d-none";
        }
    }
}