<!--
This component is a modal leaflet map where the user can select the location of the building. The component contains
a button that toggles the map and also each time the user clicks in a location in map the fields that are auto filled
in the form are longitude, latitude and altitude. To find the altitude from a place we an external API ( https://open-meteo.com/ )

This component exists in new building Form in Verify -B and -D.

app/javascript/vue/shared/AutomaticFormBuilding.vue
app/javascript/pages/building/javascript_loader.js


In order to use the component you have to
1. import the component
  eg. import LeafletMapPopup from "./leafletMap.vue";
2. use it in the vue file
  <LeafletMapPopup />
-->
<template>
  <button
    type="button"
    title="Select Coordinates on Map"
    class="btn btn-outline-danger btn-circle btn-lg"
    style="border-radius: 100%; padding-top: 5px; margin-left: 25px; margin-right: 10px;"
    @click="showMap"
  >
    <i class="fa fa-map-marker" aria-hidden="true"></i>
  </button>
  <div class="leaflet-map-popup" v-show="mapVisible">
    <div class="leaflet-map-container" style="height: 800px; width: 800px">
      <!-- Div to contain the map -->
      <div id="leaflet-map" style="height: 800px"></div>
    </div>
    <div class="leaflet-map-controls">
      <!-- Button to close the popup -->
      <button class="btn btn-outline-danger" type="button" @click="closePopup">
        Close
      </button>
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder";

export default {
  props: {
    latitude: {
      type: Number,
      default: 21,
    },
    longitude: {
      type: Number,
      default: 39,
    },
  },
  data() {
    return {
      map: null,
      marker: null,
      mapVisible: false,
      modalInitialized: false,
    };
  },
  mounted() {
    // When the component is mounted, initialize the map
    this.initializeMap();
  },
  updated() {
    if (this.mapVisible && !this.modalInitialized) {
      this.map.invalidateSize();
      this.modalInitialized = true;
    }
  },
  methods: {
    showMap() {
      this.mapVisible = !this.mapVisible;
      if (this.mapVisible) {
        this.initializeMap();
      }
    },
    initializeMap() {
      const { longitude, latitude } = this;

      if (this.map) {
        this.map.setView([latitude, longitude], 6);
        this.marker.setLatLng([latitude, longitude]);
        return;
      }

      this.map = L.map("leaflet-map", {
        center: [latitude, longitude],
        zoom: 6,
      });

      // Add tile layer
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 20,
      }).addTo(this.map);

      // Define custom marker icon
      const customIcon = L.icon({
        iconUrl: "/assets/marker.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
      });

      // Create draggable marker and add to map
      this.marker = L.marker([this.latitude, this.longitude], {
        icon: customIcon,
        draggable: true,
      }).addTo(this.map);

      this.marker.on("dragend", this.updateMarkerPosition);
      this.map.on("click", this.addMarkerAtClick);

      const geocoder = L.Control.geocoder({
        defaultMarkGeocode: false,
      })
        .on("markgeocode", (e) => {
          const latlng = e.geocode.center;
          this.map.setView(latlng, 13);
          if (this.marker) {
            this.marker.setLatLng(latlng);
          } else {
            this.marker = L.marker(latlng, {
              icon: customIcon,
              draggable: true,
            }).addTo(this.map);
            this.marker.on("dragend", this.updateMarkerPosition);
          }
          this.updateMarkerPosition({ target: this.marker });
        })
        .addTo(this.map);

      // Listen to modal shown event to handle map resizing
      $("#leafletMapModal_").on("shown.bs.modal", this.onModalShown);
    },

    async getElevation(lat, lng) {
      const url = `https://api.open-meteo.com/v1/elevation?latitude=${lat}&longitude=${lng}`;
      const options = { method: "GET" };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        const elevation = data.elevation[0];
        this.$emit("update:altitude", elevation);
        if (document.getElementById("building_altitude")) {
          document.getElementById("building_altitude").value = elevation;
        } else {
          document.getElementById("altitude").value = elevation;
        }
      } catch (error) {
        console.error(error);
      }
    },

    updateMarkerPosition(e) {
      const latlng = e.target.getLatLng();
      this.$emit("update:latitude", latlng.lat);
      this.$emit("update:longitude", latlng.lng);
      this.getElevation(latlng.lat, latlng.lng);

      if (document.getElementById("building_longitude")) {
        document.getElementById("building_longitude").value = latlng.lng;
        document.getElementById("building_latitude").value = latlng.lat;
      } else {
        document.getElementById("longitude").value = latlng.lng;
        document.getElementById("latitude").value = latlng.lat;
      }
    },
    addMarkerAtClick(e) {
      const latlng = e.latlng;
      if (this.marker) {
        this.marker.setLatLng(latlng);
        this.updateMarkerPosition({ target: this.marker });
      }
    },
    onModalShown() {
      if (this.map && !this.modalInitialized) {
        this.map.invalidateSize();
        this.modalInitialized = true;
      }
    },

    closePopup() {
      this.$emit("close-popup");
      this.mapVisible = false;
    },
  },
};
</script>

<style scoped>
.leaflet-map-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.leaflet-map-container {
  height: 400px;
}

.leaflet-map-controls {
  margin-top: 10px;
  text-align: right;
}
</style>
