let validDropDown1 = false;
let validDropDown2 = false;
let dropDown;

export default function valid_for_lca() {
  const scenario1 = $('#scenario1')
  const scenario2 = $('#scenario2')

  checkScenario(scenario1)
  checkScenario(scenario2)

  $(scenario1).change(function () {
    checkScenario(this)
  });

  $(scenario2).change(function () {
    checkScenario(this)
  });
}

function checkScenario(scenario) {
  const scenarioId = $(scenario).val();
  dropDown = $(scenario).attr("id");
  var url = window.location.href;

  // Split the URL by "/"
  var urlParts = url.split("/");

  var indexOfBuilding = urlParts.indexOf("buildings");
  if (indexOfBuilding !== -1 && indexOfBuilding + 1 < urlParts.length) {
    var buildingId = parseInt(urlParts[indexOfBuilding + 1]);
  }
  $.ajax({
    url: `/buildings/${buildingId}/scenarios/${scenarioId}/valid_for_lca`,
    type: "GET",
    success: function (response) {
      if (dropDown === "scenario1") {
        validDropDown1 = true;
      } else {
        validDropDown2 = true;
      }
      if (validDropDown1 && validDropDown2) {
        document.getElementById("submit_button").disabled = false;
        document.getElementById("invalid_scenario").style.display = "none";
      } else if (document.getElementById("scenario2").value === "" && validDropDown1) {
        document.getElementById("submit_button").disabled = false;
        document.getElementById("invalid_scenario").style.display = "none";
      }
    },
    error: function (xhr, status, error) {
      if (dropDown === "scenario1") {
        validDropDown1 = false;
      } else {
        validDropDown2 = false;
      }
      if (validDropDown1 === false || validDropDown2 === false) {
        document.getElementById("submit_button").disabled = true;
        document.getElementById("invalid_scenario").style.display = "block";
      }
      if (document.getElementById("scenario2").value === "" && validDropDown1 || error === 'Not Found') {
        document.getElementById("submit_button").disabled = false;
        document.getElementById("invalid_scenario").style.display = "none";
      }

    },
  });
}
