import { Chart } from "chart.js";

export function getChartFromId(id) {
    return Chart.getChart(id);
}

export function dynamicAdd(chart, x, y1, y2) {
    chart.config.data.labels.push(x);
    chart.config.data.datasets[0].data.push(y1);

    if (y2 !== null)
        chart.config.data.datasets[1].data.push(y2);

    chart.update();
}

export function getRandom(min, max) {
    return Math.random() * (max - min) + min;
}

export function getTimeString(timestamp) {
    // Create a Date object
    const date = new Date(timestamp);

    // Extract hours, minutes, and seconds
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');

    // Format the values as "H:M:S"
    return `${hours}:${minutes}:${seconds}`;
}